body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.codeMarker {
  background: #fff677;
  position: absolute;
  z-index: 20;
}

.react-flow__node {
  /* word-break: break-all; */
}

.ant-card-body {
  overflow-wrap: break-word;
}

.flowCard .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 6px 0px;
}

.containerStatWithTitle {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 90% 10%;
  /* place-self           : center; */
  /* background-color     : rgb(193, 193, 193); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
}

.statTitle {
  font-size: small;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  place-self: center;
}

.plotTitle {
  font-size: larger;
  margin-left: 20px;
  margin-top: 10px;
}

.statLoading {
  font-size: small;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  place-self: center;
}

.containerWithTitle {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 10% 90%;
  /* place-self           : center; */
  /* background-color     : rgb(193, 193, 193); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 20px;
}

.containerStatWithTitle:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.containerWithTitle:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.center {
  place-self: center;
}

.wordcloud {
  width: 50%;
}

.barToolTip {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px !important;
  padding-top: 4px;
  color: #333333;
  border-radius: 3px;
  background: white;
  box-shadow: 1px 1px #4b4b4b;
}

.menu {
  border-style: ridge;
  border-width: 1px;
  /* border-radius: px; */
}

.DateRangeInput {
  /* width: 400px;
  border-style: ridge;
  border-color: red; */
}
